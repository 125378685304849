.PointcloudControls {
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: $defaultFont;
  color:#FFFFFF;
  padding-top: 10px;
  user-select:none;
  cursor:default;
}

.ControllerContainer {
  display:flex;
  width:100%;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.ControllerLabel {
  width:40%;
  padding-left: 10px;
}

.Controller {
  display:flex;
  width:60%;
  padding-right: 10px;
  flex-wrap: wrap;
}

.Controller select {
  width:100%;
}

.Controller .slider {
  width:100%;
}

.Controller .PointNumber{
  width:50px;
}

.SliderWrapper {
  width:105px;
  margin-right: 10px;
}

.EDLButton {
  width: 115px;
  background-color: #222222;
  font-size: 20px;
  text-align: center;
}

.LightingControls {
  width:100%;
  background-color: #333333;
}
