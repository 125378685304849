.NotificationsContainer {
  display: flex;
  position: absolute;
  bottom: 0px; //60px
  //right: 75px;
  flex-direction: column-reverse;
  align-items: center;
  pointer-events: none;
  z-index: 99;
  //background: rgba(0,0,255,0.5);
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
}

.NotificationToast {
  display: flex;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 60px;
  font-family: Sans-Serif;
  font-size: 25px;
  text-align: center;
  background-color: #232323;
  color: #ffffff;
  padding: 10px 25px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.25s;
  user-select: none;
}

.NotificationToast.visible {
  opacity: 1;
  transform: translateY(0);
}

.ToastIcon {
  padding-right: 20px;
}

.ToastIcon i {
  font-size: 35px;
  line-height: 35px;
}
