
.TourBoxTitle {
  padding-left: 10px;
  padding-bottom: 10px;
}

.TourBoxTitle .Highlight {
    color:$titleHiglight;
}

.TourDescription {
  width:220px;
  font-size: 13px;
  padding-top:5px;
}

.TourThumbnail {
  background-color: #FF00FF;
  display:block;
  width:150px;
  height:75px;
  background-size: cover;
}

.TourEntry {
  display:flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  overflow:hidden;
  user-select:none;
  cursor: default;
}

.TourEntry:nth-child(even) {
  background-color: $panelLightAlt;
}

.ControlsContainer {
  margin-bottom: 10px;
}

.ControlsContainer .ControlButton {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #c10000;
  width:auto;
  margin-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.CloseButton {
  font-size:15px;
  display:block;
  width:50px;
  overflow:hidden;
  position:absolute;
  top:5px;
  right:0px;
}
