input[type="range"].slider {
  -webkit-appearance: none;
  margin: 6.5px 0;
  background: none;
}
input[type="range"].slider:focus {
  outline: none;
}
input[type="range"].slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  background: #bcbcbc;
  border-radius: 0px;
  border: 0px solid #010101;
}
input[type="range"].slider::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 2px solid #ffff;
  height: 15px;
  width: 15px;
  border-radius: 50px;
  background: #25b9a4;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6.5px;
}

input[type="range"].slider:focus::-webkit-slider-runnable-track {
  background: #bcbcbc;
}
input[type="range"].slider::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  background: #bcbcbc;
  border-radius: 0px;
  border: 0px solid #010101;
}
input[type="range"].slider::-moz-range-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid #00fff2;
  height: 15px;
  width: 15px;
  border-radius: 50px;
  background: #00fff2;
  cursor: pointer;
}
input[type="range"].slider::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"].slider::-ms-fill-lower {
  background: #bcbcbc;
  border: 0px solid #010101;
  border-radius: 0px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}
input[type="range"].slider::-ms-fill-upper {
  background: #bcbcbc;
  border: 0px solid #010101;
  border-radius: 0px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}
input[type="range"].slider::-ms-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid #00fff2;
  height: 15px;
  width: 15px;
  border-radius: 50px;
  background: #00fff2;
  cursor: pointer;
  height: 2px;
}
input[type="range"].slider:focus::-ms-fill-lower {
  background: #bcbcbc;
}
input[type="range"].slider:focus::-ms-fill-upper {
  background: #bcbcbc;
}
