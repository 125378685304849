@import '_variables.scss';

.MapLayerSelector {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  color: #FFFFFF;
  font-family: Sans-Serif;
  user-select: none;
  pointer-events: auto;
  position: fixed;
  bottom: 0px;
  border-top: 2px solid #FFFFFF;
  min-height: 300px;
  max-height: 600px;
  overflow-y: auto;
}

.MapLayerChoice {
  padding: 2px;
  cursor: pointer;
  transition: all 0.2s;
  padding-left: 5px;
  display:flex;
  align-items: center;
}

.MapLayerChoice i {
  font-size:16px;
  margin-right:5px;
}

.MapLayerChoice:hover {
  background-color: #555;
}

.MapLayerChoice.active {
  user-select: none;
  cursor: default;
}

.MapDrawerHeader {
  display: flex;
  cursor: pointer;
  transition: all 0.4s;
  align-items: center;
}

.MapDrawerHeader:hover {
  background-color: #555;
}

.TabHeader {
	color: #000;
  font-size: 16px;
  font-family: Sans-Serif;
  display: inline-block;
  background: #999999;
  padding: 2px 5px 0px 5px;
  border-radius: 3px 3px 0px 0px;
	margin-left:5px;
	user-select: none;
}

.MapLayerHint {
	padding: 5px;
	text-align: center;
}
