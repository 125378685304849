.GalleryContainer {
  display: none;
  position: fixed;
  right: 0px;
  width: 505px;
  max-height: 80%;
  background: #333;
  cursor: default;
  user-select: none;
  justify-content: center;
  z-index: 10;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.6);
  border-radius: 6px 6px 0px 0px;
  padding-top: 30px;
  pointer-events: all;
}

.GalleryContainer.visible {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.GalleryContainer.visible.minimized {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 0px;
  width: 200px;
}

.GalleryHeader {
  position: absolute;
  font-family: $defaultFont;
  color: $white;
  width: 100%;
  height: 30px;
  top: 0px;
  left: 0px;
  background: linear-gradient(to bottom, #6d7880 0%, #4a5666 50%, #344252 51%, #1f2739 100%);
  line-height: 30px;
  z-index: 3;
}

.EditHeader {
  display: flex;
  width: 100%;
  font-family: $defaultFont;
  color: $white;
  background-color: #333333;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4);
  z-index: 3;
  font-size: 14px;
  padding-bottom: 5px;
}

.GalleryTitle {
  font-family: $defaultFont;
  color: $white;
  padding-left: 5px;
  font-size: 20px;
  line-height: 30px;
  display: inline-block;
  padding-right: 5px;
  overflow: hidden;
  height: 30px;
  width: auto;
}

.GalleryTitle.minimized {
  width: 130px;
}

.EditControls {
  display: flex;
  font-family: $defaultFont;
  color: $white;
  align-items: center;
}

.EditControls .button {
  border: 2px solid #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  background: #822828;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  margin-left: 5px;
}

.EditControls .delbutton {
  border: 2px solid #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  background: #333;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  margin-left: 5px;
}

.EditControls .delbutton.disabled {
  opacity: 0.2;
}

.EditActionButton {
  border: 2px solid #ffffff;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 4px;
  background-color: #333;
  margin-left: 5px;
  color: #fff;
  font-family: $defaultFont;
  font-size: 14px;
  transition: background-color 0.2s;
}

.EditActionButton:hover {
  background-color: $brightTurquoise;
}

.KeyframeDragStyle {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  width: 20px;
  cursor: grab;
}

.EditControls .button:hover {
  background: $brightTurquoise;
  transition: background-color 0.2s;
}

.EditControls .delbutton:hover {
  background: $brightTurquoise;
  transition: background-color 0.2s;
}

.EditControls .delbutton.disabled:hover {
  background: inherit;
  transition: none;
}

.GalleryClose {
  position: absolute;
  top: 0px;
  right: 0px;
}

.KeyframeContainer {
  //display:flex;
  display: block;
  //flex-flow: row wrap;
  width: 100%;
  overflow-y: auto;
  //align-content: flex-start;
  //flex-direction: column;
}

.KeyframePreview {
  overflow: hidden;
  transition: background-color 0.2s;
  position: relative;
  width: 100%;
  height: 75px;
  display: flex;
  border-bottom: 1px solid #9d9d9d;
  background-color: #333;
}

.KeyframePreview:hover {
  background-color: rgba(200, 200, 200, 0.4);
}

.KeyframePreview.disabled {
  opacity: 0.2;
}

.KeyframePreview.active {
  background-color: $darkTurquoise;
}

.KeyframePreview.editing.active {
  background-color: $darkTurquoise;
}

.KeyframeThumbnail {
  width: 133px;
  height: 75px;
  display: block;
  background-color: #d800e6;
  background-size: cover;
  flex-shrink: 0;
}

.KeyframeDescription {
  font-family: $defaultFont;
  color: $white;
  font-size: 12px;
  overflow-y: auto;
  max-height: 57px;
  width: 200px;
  padding-top: 5px;
  padding-left: 5px;
}

.KeyframeName {
  font-size: 15px;
}

.KeyframeName.editable:hover {
  text-decoration: underline;
}

.KeyframeInfo {
  background-color: rgba(20, 20, 20, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  top: 55px;
  height: 20px;
}

.KeyframeDelay {
  font-family: $defaultFont;
  color: #ffffff;
  font-size: 12px;
  line-height: 20px;
}

.KeyframeDelay.editable:hover {
  text-decoration: underline;
}

.KeyframeCounter {
  font-family: $defaultFont;
  color: #ffffff;
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  left: 2px;
}

.CreateKeyframe {
  width: 100%;
  height: 75px;
  display: block;
  //padding:20px;
  overflow: hidden;
  //transition: all 0.2s;
  border: dashed 2px #ffffff;
  font-family: $defaultFont;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  line-height: 75px;
  font-size: 20px;
  box-sizing: border-box;
  border-top: none;
}

.KeyframeEditButtonPanel {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding-bottom: 2px;
  display: flex;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s;
}
.KeyframePreview.active .KeyframeEditButtonPanel {
  //display:flex;
  pointer-events: all;
  opacity: 1;
}

.KeyframePreview:hover .KeyframeEditButtonPanel {
  //display:flex;
  pointer-events: all;
  opacity: 1;
}

.KeyframeDeleteButton {
  position: absolute;
  right: 0px;
  color: #aaa;
  top: 0px;
  opacity: 0;
  transition: opacity 0.2s;
  transition: color 0.2s;
  pointer-events: none;
}

.KeyframeDeleteButton i {
  font-size: 18px;
}

.KeyframePreview.active .KeyframeDeleteButton {
  pointer-events: all;
  opacity: 1;
}

.KeyframePreview:hover .KeyframeDeleteButton {
  opacity: 1;
  pointer-events: all;
}

.KeyframeDeleteButton:hover {
  color: #f00;
}

.CreateKeyframe:hover {
  background-color: rgba(200, 200, 200, 0.4);
}

.DeleteKeyframe {
  position: absolute;
  color: #ffffff;
  background: #222222;
  opacity: 0.4;
  transition: all 0.2s;
  float: left;
}

.KeyframePreview:hover .DeleteKeyframe {
  opacity: 0.8;
}

.DeleteKeyframe:hover {
  color: #ff0000;
}

.CreateCaption {
  position: absolute;
  color: #ffffff;
  background: #222222;
  opacity: 0.4;
  transition: all 0.2s;
  right: 45px;
}

.CloneAction {
  position: absolute;
  color: #ffffff;
  background: #222222;
  opacity: 0.4;
  transition: all 0.2s;
  right: 20px;
}

.KeyframePreview:hover .CreateCaption {
  opacity: 0.8;
}

.CreateCaption:hover {
  color: $brightTurquoise;
}

.CloneAction:hover {
  color: $brightTurquoise;
}

.KeyframePreview:hover .CloneAction {
  opacity: 0.8;
}

.editSwitchContainer {
  display: flex;
  overflow: hidden;
  height: 30px;
  align-items: center;
  padding-left: 5px;
}

/** Switch
 -------------------------------------*/

.editSwitch input {
  position: absolute;
  opacity: 0;
}

/**
 * 1. Adjust this to size
 */

.editSwitch {
  display: inline-block;
  font-size: 20px; /* 1 */
  height: 1em;
  width: 2em;
  background: #111111;
  border-radius: 1em;
  margin-left: 10px;
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.7);
}

.editSwitch div {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #fff;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
}

.editSwitch input:checked + div {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  background: $switchActive;
}

.galleryEditButton {
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.2s;
  padding-top: 1px;
  padding-bottom: 1px;
  background: #333;
}

.galleryEditButton:hover {
  background: $brightTurquoise;
}

.galleryEditButton.Enabled {
  background-color: #167411;
}

.galleryEditButton:hover.Enabled {
  background-color: #089d00;
}

.galleryEditButton.Disabled {
  background-color: #983434;
}

.galleryEditButton:hover.Disabled {
  background-color: #bd0000;
}
