.RegisterForm {
  background: #222222;
  color: #FFFFFF;
  font-family: Sans-Serif;
  width: 200px;
  height: 220px;
  padding-left: 20px;
  padding-top: 20px;
  pointer-events: auto;
  position: relative;
}

.RegisterSubmitButton {
  background-color: #229022;
  width: 173px;
  margin-top: 10px;
  text-align: center;
  height: 25px;
  font-size: 20px;
  line-height: 25px;
}


.RegisterOrContainer {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #AAA;
  font-size: 14px;
}

.RegisterOrLineDivide {
  border-bottom: 1px solid #AAA;
  width: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.RegisterSubmitButton {
  background-color: #0047e4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  color: #FFF;
  font-size: 14px;
  transition: background-color 0.2s;
}

.RegisterSubmitButton:hover {
  background-color: #175fff;
}

.RegisterEmailInput {
  width: 100%;
  outline: none;
  padding: 5px;
  border-radius: 0px;
  border: 1px solid #999;
  margin-top: 10px;
  font-size: 16px;
}

.RegisterHeroElement {
  background-image: url('/static/img/hero.jpg');
  background-size: cover;
  width: 50%;
  height: 100%;
  background-color: #0047e4;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.RegisterHeroElementText {
  width: 100%;
  font-size: 35px;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7343312324929971) 50%, rgba(0,0,0,0.896796218487395) 100%);
  height: 150px;
  padding: 20px;
  padding-top: 60px;
}

.ProjectDetailsTextArea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  border: 1px solid #999;
  outline: none;
  resize: none;
  padding:5px;
}

.RegisterErrorMessage {
  color: #FF0000;
  font-size: 15px;
  padding-top: 5px;
  font-weight: bold;
}

.RegisterCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #AAA;
  transition: color 0.2s;
}

.RegisterCloseButton:hover {
  color:#000;
}

.RegisterBackButton {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #AAA;
  transition: color 0.2s;
}

.RegisterBackButton:hover {
  color:#000;
}
