@import "_variables.scss";
@import "SideBar.scss";
@import "ViewerButtons.scss";
@import "TourMenu.scss";
@import "Slides.scss";
@import "LoadingPlaceholder.scss";
@import "PointcloudControls.scss";
@import "Slider.scss";
@import "AnimationControls.scss";
@import "Gallery.scss";
@import "Notifications.scss";
@import "MapLayerSelector.scss";
@import "LoginContainer.scss";
@import "PopupContainer.scss";
@import "RegisterForm.scss";
@import "CompanyForm.scss";
@import "Leaflet.scss";
@import "Leaflet-MarkerCluster.scss";
@import "ProjectDirectory.scss";
@import "PasswordForm.scss";
@import "ReactDatepicker.scss";
@import "ReactDatepickerCssmodules.scss";
@import "TenementDirectory.scss";
@import "AnalyticsDirectory.scss";
@import "Meet.scss";
@import "welcome.scss";
@import "Editor.scss";

body {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  background-color: #000;
  cursor: default;
}

#Inventum3D {
  background-position: 0px 0px, 10px 10px;
  background-size: 20px 20px;
  background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),
    linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
}

.NoJavascriptWarning {
  width: 100%;
  height: 100%;
  background-color: #333333;
  position: absolute;
  color: #ffffff;
  font-family: Google Sans;
  text-align: center;
  padding-top: 10px;
  font-size: 30px;

  a {
    color: #ffffff;
  }
  a:visited {
    color: #ffffff;
  }
  a:hover {
    color: #ffffff;
  }
  a:active {
    color: #ffffff;
  }

  .issue {
    background-color: #ff0000;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.leaflet-container {
  background: #aad3df !important;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -10;
}

canvas {
  position: absolute;
  left: 0px;
  top: 0px;
  background: #000000;
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: -99;
}

.map-vertical-button {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background: #232323;
  margin-bottom: 10px;
  text-align: center;
  transition: all 0.2s;
  cursor: pointer;
  user-select: none;
}

.map-vertical-button.active {
  background: $buttonHover;
}

.map-vertical-button:hover {
  background: $buttonHover;
  transform: scale(1.2, 1.2);
}

.map-vertical-button-group {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background: #444444;
  margin-bottom: 10px;
  text-align: center;
  transition: all 0.2s;
  cursor: default;
  user-select: none;
  position: relative;
}

.map-vertical-button .material-icons {
  color: #ffffff;
  line-height: 45px;
  font-size: 25px;
}

.map-vertical-button.disabled .material-icons {
  color: #222222;
  line-height: 45px;
  font-size: 25px;
}

.map-vertical-controls {
  position: absolute;
  z-index: 5;
  width: 50px;
  right: 10px;
  bottom: 0px;
  margin-bottom: 50px;
  pointer-events: all;
  transition: opacity 0.4s;
}

.menu-controls-info {
  color: #000000;
  position: absolute;
  z-index: 3;
  left: 0px;
  top: 5px;
  user-select: none;
  cursor: default;
  transition: transform 0.5s;
  transform: translate(0px, 0px);
  display: flex;
  flex-direction: row;
}

.menu-controls-info.moved {
  transform: translate(325px, 0px);
}

@media only screen and (max-width: 1000px) {
  .menu-controls-info.moved {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .menu-controls-info {
    flex-direction: column;
  }

  body .projectInfo {
    top: 35px;
    left: 0px;
  }

  body .projectInfo.moved {
    display: none;
  }
}

.sidebarToggleButton {
  position: absolute;
  top: 0px;
  left: 0px;
}

.sidebarToggleButton .material-icons {
  color: #ffffff;
  text-shadow: 2px 2px 4px #000000;
  font-size: 50px;
  line-height: 28px;
}

.projectInfo {
  padding-left: 5px;
  font-family: Google Sans;
  color: #ffffff;
  text-shadow: 2px 2px 4px #000000;
  display: flex;
  position: absolute;
  top: 0px;
  left: 50px;
  align-items: center;
  transition: opacity 0.4s;
}

.projectInfo.moved {
  left: 355px;
}

.linkBox {
  display: inline-block;
  z-index: 5;
  color: #ffffff;
  vertical-align: middle;
  line-height: 25px;
  font-size: 15px;
  cursor: default;
  transition: all 0.2s;
  font-family: Sans-Serif;
  user-select: none;
  margin-top: 10px;
  border-radius: 6px;
}

.linkBox a {
  text-decoration: none;
  color: #ffffff;
}

.linkBox .material-icons {
  font-size: 15px;
  padding-right: 5px;
  position: relative;
  top: 2px;
}

.companyLink {
  height: 25px;
  width: 200px;
  background: #232323;
  line-height: 25px;
  padding-left: 10px;
  transition: all 0.25s;
  border-radius: 6px;
}

.companyLink:hover {
  background: $buttonHover;
  cursor: pointer;
}

.companyLink .material-icons {
  font-size: 15px;
  padding-right: 5px;
  position: relative;
  top: 2px;
}

.inventumLink .inventumLogo {
  fill: #34b7a3;
}

.inventumLink:hover .inventumLogo {
  fill: #ffffff;
}

.inventumLink:hover {
  background: $buttonHover;
  cursor: pointer;
}

.inventumLink {
  border-top: 1px solid #9d9d9d;
  background: #232323;
  height: 25px;
  line-height: 25px;
  padding-left: 10px;
  width: 200px;
  transition: all 0.25s;
  border-radius: 6px;
  vertical-align: middle;
}

.companyHeading {
  font-size: 20px;
  user-select: none;
}

.companyHeading a {
  color: #ffffff;
  text-decoration: none;
}

.projectHeading {
  font-size: 35px;
  font-weight: bold;
  user-select: none;
}

.crossIcon {
  background: red;
  height: 100px;
  position: relative;
  width: 20px;
  transform: rotateZ(45deg);
}
.crossIcon:after {
  background: red;
  content: "";
  height: 20px;
  left: -40px;
  position: absolute;
  top: 40px;
  width: 100px;
}

.sortableHelper {
  z-index: 99;
}

.map-project-label {
  background: rgba(34, 34, 34, 0.6);
  color: #ffffff;
  padding: 0px 5px 0px 5px;
  font-family: $defaultFont;
}

/*.map-company-name {
  display: none;
  transition: all 0.25s;
}*/

.map-project-label:hover .map-company-name {
  display: block;
}

/*.map-company-name:hover {
  color: #00e9c8;
}*/

.map-project-name {
  transition: all 0.25s;
  text-decoration: underline;
}

.map-project-name:hover {
  color: #00e9c8;
}

.map-horizontal-controls-bottom {
  position: fixed;
  bottom: 0px;
  display: flex;
}

.AboutInventumButton {
  background: #444444;
  color: #ffffff;
  transition: all 0.2s;
  font-family: Google Sans;
  user-select: none;
  min-width: 140px;
  display: flex;
  align-items: center;
  border-left: 1px solid #ffffff;
  padding-left: 5px;
  padding-right: 5px;
}

.AboutInventumButton.map {
  bottom: 0px;
}

.AboutInventumButton img {
  height: 20px;
  padding-left: 5px;
}

.AddProjectButton {
  font-family: Google Sans;
  color: #ffffff;
  background: #444444;
  padding: 5px;
  min-width: 140px;
  transition: all 0.2s;
}

.AddProjectButton span i {
  font-size: 15px;
}

.AddProjectButton:hover {
  background: $buttonHover;
}

.AboutInventumButton:hover {
  background: $buttonHover;
}

#ThumbnailLoadingScreen {
  width: 100%;
  height: 100%;
  background-color: #222222;
  color: #ffffff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-family: Google Sans;
  background-size: cover;
  z-index: 10;
}

.HelpContent {
  padding: 5px;
  font-size: 15px;
}

.InventumMapTooltip {
  background: rgba(34, 34, 34, 0.6);
  border: 0px;
  border-radius: 4px;
  color: #ffffff;
  font-family: Google Sans;
  padding: 0px 5px 0px 5px;
  pointer-events: auto;
  cursor: pointer;
  margin-left: 10px;
}

.InventumMapTooltip.reverseTooltip {
  margin-left: -10px;
}

.InventumMapTooltipBackboard {
  display: flex;
  flex-direction: column;
}

.map-company-name {
  display: none;
  text-decoration: underline;
}

.InventumMapTooltip:hover {
  padding: 0px 5px 0px 5px;
}

.InventumMapTooltipBackboard:hover .map-company-name {
  display: block;
}

// .InventumMapTooltipExpand {
//   padding: 2px 5px;
//   background: #474747;
//   margin-left: 5px;
// }

.map-company-name:hover {
  color: #00e9c8;
  display: flex;
}

.InventumLogoImg {
  width: 200px;
  padding: 5px;
  background: #333333;
  margin-top: 5px;
  border-radius: 30px;
  opacity: 80%;
}

.BarNotification {
  z-index: 5;
  width: 100%;
  height: 50px;
  background-color: #333333;
  bottom: 0px;
  position: absolute;
  font-family: Google Sans;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(50px);
  transition: all 0.5s;
  font-size: 15px;
}

.BarNotification.visible {
  transform: translateY(0px);
}

.BarNotification .DismissButton {
  padding: 5px;
  background: #222;
  display: block;
  margin-left: 10px;
  pointer-events: all;
  transition: all 0.2s;
}

.BarNotification .DismissButton:hover {
  background: $brightTurquoise;
}

.BarNotification i {
  overflow: hidden;
  height: 20px;
  color: #ff0000;
}

.SceneControlsButtonLeft {
  padding: 5px;
  padding-left: 8px;
  background: #232323;
  user-select: none;
  transition: all 0.2s;
  border-radius: 6px;
}

.SceneControlsButtonLeft:hover {
  background: $brightTurquoise;
}

.publishReviewButton {
  padding: 5px;
  padding-left: 8px;
  background: #232323;
  border-top: 1px solid #9d9d9d;
  transition: all 0.2s;
  border-radius: 6px;
}

.publishReviewButton:hover {
  background: $brightTurquoise;
}

.changeVersionButton {
  padding: 5px;
  padding-left: 8px;
  background: #232323;
  height: fit-content;
  user-select: none;
  transition: all 0.2s;
  border-bottom: 1px solid #9d9d9d;
  border-radius: 6px;
}

.changeVersionButton:hover {
  background: $brightTurquoise;
}

.projectControlsContainer {
  position: absolute;
  right: 0px;
  top: 30px;
  border: none;
  color: #ffffff;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  font-family: Google Sans;
  pointer-events: all;
  transition: opacity 0.4s;
}

.versionInfoBox {
  width: 100%;
  text-align: center;
  font-size: 25px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  font-weight: bold;
  user-select: none;
}

.versionSelectorBox {
  display: flex;
  flex-direction: column;
  user-select: none;
  cursor: pointer;
}

.NoLinkDecoration {
  color: #ffffff;
}

.NoLinkDecoration:visited {
  color: #ffffff;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.leaflet-popup-content {
  width: auto !important;
}

.tenementPopupInfoTable {
  border: 1px solid #fff;
}

.tenementsLegend {
  position: absolute;
  z-index: 10000;
  background: #333;
  display: block;
  right: 0px;
  bottom: 25px;
  padding: 10px;
  color: #fff;
  font-size: 15px;
}

.TenementLegendSwatch {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.ViewerPage {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.projectLocationButton {
  color: #ffffff;
  font-size: 15px;
  padding-right: 5px;
  transition: all 0.2s;
}

.projectLocationButton:hover {
  color: $brightTurquoise;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
