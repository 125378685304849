.MeetingViewerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
  user-select: none;
}

.MeetingViewerContainer.connecting {
  background: linear-gradient(45deg, rgba(0, 117, 166, 1) 0%, rgba(74, 255, 229, 1) 100%);
  font-size: 50px;
  color: #fff;
  font-family: Sans-Serif;
}

.MeetingViewerContainer.disconnected {
  background: #333;
  font-size: 50px;
  color: #fff;
  font-family: Sans-Serif;
}

.MeetingChatContainer {
  background: #333333;
  height: 300px;
  position: absolute;
  bottom: 60px;
  right: 70px;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 1);
  font-family: Sans-Serif;
  padding: 5px;
  pointer-events: auto;
  z-index: 5;
  font-size: 14px;
}

.MeetingChatLeftPanel {
  width: 100px;
  border-right: 1px solid #fff;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.MeetingChatRightPanel {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.MeetingChatUsersBox {
  height: 100%;
  color: #fff;
  overflow-y: auto;
  padding-right: 5px;
}

.MeetingChatBottomBar {
  display: flex;
  flex-direction: column;
}

.MeetingChatMessagesBox {
  width: 300px;
  height: 100%;
  padding: 5px;
  overflow-y: auto;
  font-size: 20px;
  color: #ffffff;
}

.MeetingChatMessageInput {
  width: 100%;
  outline: none;
  border-radius: 5px;
  padding-left: 5px;
  font-size: 15px;
  resize: none;
}

.MeetingChatMessageHeader {
  display: flex;
}

.MeetingChatMessage {
  display: flex;
  padding-bottom: 5px;
  font-size: 14px;
  padding-top: 5px;
}

.MeetingChatMessageContent {
  white-space: pre-line;
}

.MeetingChatMessageUsername {
  padding-right: 5px;
  font-weight: bold;
}

.MeetingChatMessageTimestamp {
  color: #888888;
  font-size: 12px;
}

.MeetingChatAvatar {
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}

.MeetingChatAvatarImage {
  width: 40px;
  height: 40px;
  background: #ff0000;
  border-radius: 50%;
}

.MeetingChatUserStatusBox {
  padding-top: 2px;
  border-top: 1px solid #fff;
  color: #fff;
  font-weight: bold;
}

.DrawingControls {
  position: absolute;
  right: 0px;
  top: 180px;
  font-family: sans-serif;
  background: #232323;
  color: #ffffff;
  z-index: 5;
  pointer-events: all;
  user-select: none;
}

.DrawingControlsHeader {
  padding: 5px;
  font-weight: bold;
  text-align: center;
}

.DrawingControlsButton {
  padding: 5px;
  border-top: 1px solid #fff;
  transition: all 0.2s;
}

.DrawingControlsButton:hover {
  background: $brightTurquoise;
}

.MeetingChatIsTyping {
  height: 10px;
  display: block;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  padding: 3px;
}

.EndMeetingButton {
  text-align: center;
  background: #bd1616;
  color: #ffffff;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  margin-bottom: 5px;
  border-radius: 5px;
  transition: all 0.2s;
  margin-bottom: 5px;
}

.EndMeetingButton:hover {
  background: #ff0000;
}

.MeetAdminContainer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  background: #222222;
  font-family: sans-serif;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.MeetingCreatorPasswordInput {
  width: 300px;
}
.MeetingCreatorTimeInput {
  width: 300px;
}

.ConnectionUIBox {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
  color: #333333;
  box-shadow: 2px 2px 6px rgba(0.2, 0.2, 0.2, 0.8);
  font-size: 15px;
  max-width: 400px;
}

.MeetingPasswordTitle {
  width: 100%;
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: bold;
}

.MeetingPasswordInfo {
  padding-bottom: 20px;
}

.MeetingPasswordInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: bold;
}

.MeetingPasswordInputContainer input {
  height: 30px;
  font-size: 20px;
  border: none;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.8);
  margin-top: 5px;
  background: #fafafa;
  padding-left: 5px;
  outline: none;
}

.PasswordSubmitButtonsContainer {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding-top: 20px;
}

.MeetingPasswordButton {
  padding: 10px;
  border-radius: 15px;
  width: 100px;
  text-align: center;
  background: #ef3939;
  font-weight: bold;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  color: #ffffff;
  transition: all 0.2s;
}

.MeetingPasswordButton:hover {
  background-color: #f50f0f;
}

.PasswordCancelButton {
  background: #f7f7f7;
  color: #222222;
  transition: all 0.2s;
}

.PasswordCancelButton:hover {
  background: #e2e2e2;
}

.MeetingButtonDisabled {
  background: #aaaaaa;
  opacity: 0.2;
}

.MeetingButtonDisabled:hover {
  background: #aaaaaa;
}

.ChatAdminControlsContainer {
  border-bottom: 1px solid #ffffff;
  background: #222222;
  padding: 2px;
}

.ChatUserEntry {
  transition: color 0.2s;
  padding-top: 2px;
  padding-bottom: 2px;
}
.ChatUserEntry:hover {
  color: $brightTurquoise;
}

.ChatAdminControlsEntry {
  color: #ffffff;
  transition: color 0.2s;
}

.ChatAdminControlsEntry:hover {
  color: $brightTurquoise;
}

.meetingProjectTitle {
  color: #ffffff;
  text-shadow: 2px 2px 4px #000000;
  font-size: 35px;
  font-weight: bold;
  user-select: none;
  font-family: $defaultFont;
  position: absolute;
  top: 65px;
  left: 55px;
}

.meetingCompanyTitle {
}
