.welcomePage {

}

.welcomeContentWrapper {
  overflow-y: scroll;
  height: 100%;
  position: absolute;
  left: 0px;
  width: 100%;
  top:0px;
}

.welcomeTitle {
  width: 100%;
  background: #FFF;
  height: 64px;
  position: absolute;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 5;
  //box-shadow: 0 2px 2px rgba(0,0,0,0.4);
}

.welcomeLinksBar {
  display: flex;
  font-family: sans-serif;
  padding: 10px;
  font-size: 15px;
  right: 200px;
  position: absolute;
}

.welcomeLinksBar div {
  padding-left: 40px;
}

.welcomeHero {
  width: 100%;
  height: 600px;
  background: #FFF;
  display: flex;
}

.welcomeHeroButton {
  background-color: #0047e4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 2px;
  color: #FFF;
  transition: background-color 0.2s;
  width: 200px;
  height: 40px;
  font-size: 25px;
  margin-top: 20px;
}

.welcocomeHeroInteractive {
  display:flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.welcomeHeroText {
  width: 50%;
  height: 100%;
  background: #FFF;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  flex-shrink: 0;
}

.welcomeHeroTextWrapper {
  width: 50%;
}

.welcomeHeroTextTitle {
  font-size: 70px;
  font-family: 'Merriweather', serif;
}

.welcomeHeroTextSubtitle {
  padding-top: 30px;
  font-size: 20px;
}

.welcomeMap {
  height: 600px;
  background: #333;
}

.welcomeGallery {
  width: 100%;
  background: #fff3e1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
}

.welcomeGallery div {
  width: 300px;
  height: 300px;
  background: #549fF9;
  margin: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-family: sans-serif;
  color: #FFF;
  background-size:cover;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.5);
}


.welcomeFeatures {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  font-size: 20px;
  flex-direction: column;
}

.welcomeFeatures div {
  width:80%;
}

.welcomeFooter {
  width: 100%;
  background: #222;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcomeFooter div {
  font-family: sans-serif;
  font-size: 30px;
  width: 50%;
  text-align: center;
  font-size: 40px;
  color: #FFF;
}
