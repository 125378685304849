.PopupContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  user-select: none;
  z-index: 99;
}

.PopupCloseButton {
  position: absolute;
  top: 0px;
  right: 0px;
}

.WorldEditorContainer {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #333333;
  font-size: 20px;
  color: #ffffff;
  font-family: Sans-Serif;
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 99;
  border-radius: 6px 6px 0px 0px;
  padding-top: 30px;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.6);
  max-width: 90%;
}

.WorldEditorContainer.minimized {
  height: 0px;
  width: 200px;
}

.WorldEditorDrawer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.WorldEditorButton {
  height: 20px;
  font-family: $defaultFont;
  color: #ffffff;
  text-align: center;
  line-height: 20px;
  background-color: $darkTurquoise;
  transition: all 0.5s;
  padding: 5px;
  border-radius: 10px;
  margin-top: 5px;
}

.WorldEditorButton.Enabled {
  background-color: #167411;
}

.WorldEditorButton:hover.Enabled {
  background-color: #089d00;
}

.WorldEditorButton.Disabled {
  background-color: #983434;
}

.WorldEditorButton:hover.Disabled {
  background-color: #bd0000;
}

.WorldEditorButton.Unavailable {
  background-color: #222222;
}

.WorldEditorButton:hover.Unavailable {
  background-color: #222222;
}

.WorldEditorButton:hover {
  background-color: $brightTurquoise;
}

.TransformInputContainer {
  display: flex;
  margin-bottom: 10px;
}

.TransformInputField {
  input {
    width: 40px;
    margin-left: 5px;
  }
  margin-right: 10px;
}

.LightTableSetting {
  th,
  td {
    width: 100px !important;
  }
}

.AssetTableSetting {
  th,
  td {
    width: 100px !important;
  }
}

.LightsEditorContainer {
  tbody {
    display: block;
    max-height: 400px;
    overflow-y: auto;
  }

  thead {
    display: block;
  }

  th {
    text-align: center;
    padding: 5px;
    font-size: 12px;
    width: 50px;
  }

  td {
    width: 50px;
    padding: 5px;
    font-size: 12px;
    input {
      width: 20px;
    }
  }

  tr {
    background-color: #545454;
    transition: background-color 0.2s;
  }

  tr:nth-child(odd) {
    background-color: #444444;
  }
}

.LightsEditorContainer tbody tr:hover {
  background: $darkTurquoise;
}

.CSSEditorRow {
  th,
  td {
    width: 100px;
  }
}

.CSSEditorCopyPasteContainer {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  border-top: 1px solid #ffffff;
}

.ElementsEditorRow {
  th,
  td {
    width: 50px;
  }
}

.StoragePathInfoBox {
  font-size: 12px;
  text-align: center;
  user-select: all;
}

.AssetManagerContainer {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  padding: 10px;
  background-color: #333333;
  font-size: 20px;
  color: #ffffff;
  font-family: Sans-Serif;
  width: 30%;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
}

.AssetRefreshButton {
  position: absolute;
  top: 0px;
  left: 145px;
  font-size: 15px;
  padding: 3px 5px 3px 5px;
  text-decoration: underline;
  line-height: 30px;
}

.popupDragHandle {
  position: absolute;
  width: 100%;
  height: 30px;
  top: 0px;
  left: 0px;
  background: linear-gradient(to bottom, #6d7880 0%, #4a5666 50%, #344252 51%, #1f2739 100%);
  line-height: 30px;
}

/*
position: absolute;
top: 2px;
right: 5px;
*/

.titlebarCloseButton {
  background: #f42c05a3;
  height: 24px;
  border: 1px solid #3b4353;
  transition: all 0.25s;
  border-radius: 5px;
  margin-left: 3px;
}

.titlebarCloseButton:hover {
  background: #f42c05;
}

.titlebarMinMaxButton {
  background: #525e6c8f;
  height: 24px;
  border: 1px solid #3b4353;
  transition: all 0.25s;
  border-radius: 5px;
}

.titlebarMinMaxButton:hover {
  background: #365477;
}

.windowControlsContainer {
  top: 1px;
  right: 2px;
  position: absolute;
  display: flex;
}

.labelTextRecord {
  background-color: #222222;
  border-radius: 3px;
  padding: 3px;
  font-size: 12px;
  margin-right: 5px;
  user-select: text;
  overflow: hidden;
  white-space: nowrap;
  max-width: 50px;
  text-overflow: ellipsis;
}

.createLabelTextRecord {
  background-color: #588c03;
  border-radius: 3px;
  padding: 3px;
  font-size: 12px;
  margin-right: 5px;
}

.clearLabelTextRecord {
  background-color: #8c1703;
  border-radius: 3px;
  padding: 3px;
  font-size: 12px;
  margin-right: 5px;
}

.RegisterInterestText {
  font-size: 14px;
}

.RegisterInterestForm {
  display: flex;
  flex-direction: column;
}
.RegisterInterestForm input {
  border: none;
  padding-left: 5px;
  margin-bottom: 5px;
  height: 25px;
  font-size: 15px;
  font-family: Sans-Serif;
}

.LimitTableContainer {
  padding: 10px;
  background: #646464;
  border-radius: 5px;
  margin: 10px;
}

.helpModal {
  a:link {
    color: $darkTurquoise;
    background-color: transparent;
    text-decoration: none;
  }
  a:visited {
    color: $brightTurquoise;
    background-color: transparent;
    text-decoration: none;
  }
  a:hover {
    color: $brightTurquoise;
    background-color: transparent;
    text-decoration: underline;
  }
  a:active {
    color: $brightTurquoise;
    background-color: transparent;
    text-decoration: underline;
  }
}
