.AnalyticsDirectoryContainer {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 4;
  pointer-events: auto;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
}

.AnalyticsDataViewerContainer {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  max-height: calc(100% - 30px);
  overflow-y: auto;
  width: 100%;
  max-width: 1200px;
}

.AnalyticsDataViewerContainerPanel {
  display: flex;
  width: 100%;
}

.YearLineChart {
  background: #333;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  width: 100%;
  box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.6);
}

.MonthLineChart {
  background: #333;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.6);
}

.AnalyticsListValues {
  max-height: 400px;
  width: 100%;
  color: #fff;
  background: #333;
  border-radius: 5px;
  margin: 10px;
  padding: 10px 5px 10px 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.6);
}

.AnalyticsTableStickyHeader {
  position: sticky;
  top: 0;
  background: #333;
}

.AnalyticsHeader {
  background: #333;
  color: #fff;
  border-radius: 5px;
  padding: 20px;
  margin: 8px 8px 0px 8px;
  width: 100%;
  box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  position: relative;
}

.AnalyticsHeaderSpan {
  position: absolute;
  padding-left: 5px;
  width: 200px;
  color: $brightTurquoise;
  text-decoration: underline;
  font-size: 17px;
}

.AnalyticsSelectorMenu {
  position: absolute;
  background: #fff;
  color: #000;
  top: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.6);
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.AnalyticeSelectorEntry {
  padding-left: 10px;
  padding-right: 10px;
  color: #000000;
  font-size: 15px;
  line-height: 40px;
}

.AnalyticeSelectorEntry.selected {
  color: $darkTurquoise;
  font-weight: bold;
}

.AnalyticeSelectorEntry:hover {
  background-color: $silver;
}

.AnalyticsListEntry:nth-child(odd) {
  background: #555;
}

.AnalyticsDateSelectorMenu {
  position: absolute;
  background: #fff;
  color: #000;
  top: 0px;
  box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.6);
  z-index: 10;
  border-radius: 5px;
}

.AnalyticsLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AnalyticsLoadingBox {
  padding: 20px;
  background: #333;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 20px;
  box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.6);
  position: relative;
}

.AnalyticsCloseButton {
  position: absolute;
  top: 2px;
  right: 2px;
}

.ReportGeneratedBox {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 10px;
  color: #aaa;
}

.UpdateDatesButton {
  background: #333;
  margin: 5px;
  padding: 5px;
  color: #fff;
  transition: all 0.2s;
}

.UpdateDatesButton:hover {
  color: #fff;
  background: #25b9a4;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media print {
  @page {
    size: portrait;
  }
  body * {
    visibility: hidden;
  }
  .analyticsReportPrintable,
  .analyticsReportPrintable * {
    visibility: visible;
    box-shadow: none;
    color: #000000;
    overflow: visible;
    background: hidden;
  }

  .recharts-tooltip-wrapper {
    display: none;
  }

  .YearLineChart {
    margin: 0px;
    transform: scale(0.75);
  }

  .MonthLineChart {
    margin: 0px;
    transform: scale(0.75);
  }

  .analyticsReportPrintable {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
