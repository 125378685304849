//Colors
$charcoal: #222222;
$mineShaft: #353535;
$tundora: #444444;
$silverChalice: #9d9d9d;
$persianGreen: #25b9a4;
$brightTurquoise: #25b9a4;
$darkTurquoise: #178878; //1da692
$white: #ffffff;
$gallery: #eaeaea;
$silver: #bcbcbc;
$offBlack: #222222;
$grayAlt: #545454;
$redWarning: #a10000;
$redWarningDark: #810000;

//Color Types
$panelDark: $charcoal;
$panelLight: $tundora;
$mapButtons: $tundora;
$panelLightAlt: $mineShaft;
$panelOpened: $persianGreen;
$tabSelected: $brightTurquoise;
$buttonInactive: $silverChalice;
$panelBorder: $silverChalice;
$buttonActive: $white;
$menuHeading: $gallery;
$menuSubheading: $silver;
$projectInfo: $offBlack;
$inventumTitle: $white;
$inventumDescription: $gallery;
$dropDownBackground: $gallery;
$dropDownHover: $silver;
$buttonHover: $brightTurquoise;
$titleHiglight: $brightTurquoise;
$panelEntryAlt: $grayAlt;
$buttonGlow: $brightTurquoise;
$timelineThumb: $brightTurquoise;
$activePip: $brightTurquoise;
$switchActive: $brightTurquoise;

//Fonts
$defaultFont: Google Sans;

//Backgrounds
$bgBlackTransparent: rgba(20, 20, 20, 0.8);
