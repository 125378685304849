.LoadingContainer {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
  z-index: 5;
  z-index: 12;
  pointer-events: none;
}

.LoadingText {
  font-family: $defaultFont;
  color: #ffffff;
  text-align: center;
}

.LoadingSimpleBox {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85);
  pointer-events: auto;
  background: #232323;
  padding: 10px;
  font-family: Sans-Serif;
  color: #ffffff;
  display: flex;
  margin-bottom: 60px;
  font-size: 25px;
}

.TourPromptBox {
  display: flex;
}

.LoadingIndicator {
  display: flex;
}

.TourCountdownBox {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85);
  display: block;
  background: #232323;
  pointer-events: auto;
  color: #fff;
  font-family: Sans-Serif;
  padding: 10px;
  font-size: 20px;
  margin-bottom: 60px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.25s;
  user-select: none;
  border-radius: 10px;
}

.TourCountdownBox.visible {
  opacity: 1;
  transform: translateY(0);
}

.CountdownControls {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.CountdownButton {
  padding: 5px;
  font-size: 20px;
  transition: all 0.25s;
  border-radius: 5px;
}

.CountdownButton.start {
  background: $darkTurquoise;
}

.CountdownButton.start:hover {
  background: $brightTurquoise;
}

.CountdownButton.cancel {
  background: $redWarningDark;
}

.CountdownButton.cancel:hover {
  background: $redWarning;
}

.LoadingBox {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85);
  width: 800px;
  pointer-events: auto;
}

.LoadingLower {
  display: flex;
}

.TourContainer {
  height: 170px;
  width: 100%;
  background-color: #444;
  border-top: 2px solid #ffffff;
  position: relative;
}

.TourTitle {
  font-family: Sans-Serif;
  color: #ffffff;
  font-size: 55px;
  width: 50%;
  overflow: hidden;
  height: 100%;
  display: flex;
  text-shadow: 2px 2px 4px #000000;
  align-items: center;
  justify-content: center;
}

.TourTextContainer {
  display: inline-block;
  position: absolute;
  left: 30px;
}

.TourTextContainer .projectHeading {
  font-size: 35px;
}

.TourTextContainer .companyHeading {
  padding-top: 10px;
}

.TourButtons {
  width: 50%;
  right: 0px;
  top: 0px;
  height: 100%;
  position: absolute;
}

.NewsContainer {
  height: 350px;
  display: block;
  width: 400px;
  border-left: 1px solid #ffffff;
  border-top: 2px solid #ffffff;
}

.NewsButtonsContainer {
  display: flex;
  background-color: #444;
  justify-content: flex-end;
  align-items: center;
  height: 49px;
}

.TourButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #eee;
  font-family: Sans-Serif;
  transition: all 0.2s;
  user-select: none;
  cursor: default;
  box-shadow: 2px 2px 4px rgba(20, 20, 20, 0.8);
  padding-right: 10px;
}

.TourButton.skip {
  background-color: #616161;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  right: 40px;
  bottom: 10px;
  position: absolute;
}

.TourButton.skip.loaded {
  justify-content: center;
  padding-left: 10px;
}

.TourButton.start {
  background-color: #9e191f;
  font-size: 25px;
  height: 80px;
  line-height: 60px;
  margin-right: 10px;
  width: 200px;
  position: absolute;
  left: 150px;
  top: 40px;
}

.TourButton.start.loaded:hover {
  color: #444;
}

.TourButton.start.loaded {
  background-color: $persianGreen;
  justify-content: center;
  font-size: 45px;
}

.SettingsButton {
  width: 50%;
  background-color: #444;
  color: #eee;
  font-family: $defaultFont;
  font-size: 30px;
  line-height: 50px;
  height: 49px;
  text-align: center;
}

.NewsItemsContainer {
  height: 270px;
  overflow-y: scroll;
}

.NewsItem {
  padding-left: 10px;
  font-family: Sans-Serif;
  color: #ffffff;
  padding-bottom: 5px;
  background-color: #333333;
  font-size: 12px;
  line-height: 15px;
  padding-top: 5px;
}

.NewsItem:nth-child(odd) {
  background-color: $panelEntryAlt;
}

.NewsTitle {
  height: 25px;
  font-family: Sans-Serif;
  font-size: 25px;
  color: #ffffff;
  padding-left: 10px;
  line-height: 20px;
  padding-top: 5px;
  background-color: #444;
  border-bottom: 1px solid #ffffff;
}

.TutorialContainer {
  display: block;
  width: 800px;
  background-color: #444;
  padding-top: 10px;
  padding-bottom: 10px;
}

.TutorialContainerTitle {
  text-align: center;
  font-family: $defaultFont;
  font-size: 40px;
  color: #ffffff;
  padding-bottom: 15px;
  text-shadow: 2px 2px #000000;
  user-select: none;
  cursor: default;
}

.ActionsContainer {
  display: flex;
}

.TutorialEntry {
  width: 275px;
  display: block;
  text-align: center;
  font-family: $defaultFont;
  color: #ffffff;
  user-select: none;
  cursor: default;
}

.TutorialTitle {
  font-size: 20px;
  margin-bottom: 10px;
  text-shadow: 2px 2px #000000;
  user-select: none;
  cursor: default;
}

.TutorialIcon i {
  font-size: 60px;
  line-height: 60px;
  text-shadow: 2px 2px #000000;
  user-select: none;
  cursor: default;
}

.TutorialResult {
  margin-top: 10px;
  font-size: 30px;
  text-shadow: 2px 2px #000000;
  user-select: none;
  cursor: default;
}

.TourButton.skip.loaded:hover {
  color: $buttonGlow;
}

.TourContainer .LoadingText {
  font-family: Sans-Serif;
  color: #ffffff;
  text-align: left;
  position: absolute;
  font-size: 50px;
  top: 38px;
  left: 55px;
  text-shadow: 2px 2px #000000;
}

.TourContainer .sk-folding-cube {
  position: absolute;
  right: 100px;
  top: 55px;
  width: 60px;
  height: 60px;
}

.LoadingGroup {
  padding: 10px;
  width: 150px;
  background-color: #333;
}

.LoadingTextSmall {
  font-size: 30px;
}

.sk-folding-cube {
  width: 20px;
  height: 20px;
  position: relative;
  padding-right: 15px;
  padding-left: 10px;
  //-webkit-transform: rotateZ(45deg);
  //        transform: rotateZ(45deg);
}

.TourButton.start .sk-folding-cube {
  width: 40px;
  height: 40px;
  margin-left: 20px;
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $buttonHover;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
