.TenementsDirectoryContainer {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  margin:10px;
  margin-top:0px;
}

.TenementsWizardResultsContainer {
  width: 100%;
  background-color: #222222;
}

.TenementsWizardResultsContainer .HoldersList {
  overflow-y: hidden;
  height: 200px;
}

.TenementsWizardResultsContainer .Title {
  font-size: 16px;
  padding-left: 5px;
  border-top: 1px solid #FFFFFF;
}

.TenementsWizardResultsContainer .FilteredInfo {
  font-size: 16px;
  border-bottom: 1px solid #FFFFFF;
  padding-left: 5px;
}

.HoldersList .TenementHolderResult {
  font-size: 16px;
  padding-bottom: 2px;
  padding-top:2px;
  padding-left: 5px;
}

.HoldersList .TenementHolderResult.Filtered {
  opacity:0.2;
}


.HoldersList .TenementHolderResult:nth-child(even) {
  background-color: $panelLightAlt;
}

.TenementDateSelector {
  margin-right: 10px;
  margin-bottom: 10px;
}

.TenementDateSelector input {
  width:100px;
}

.MapControlsButton {
  transition: all 0.2s;
  font-size:15px;
  background:#222222;
  padding:5px;
  margin-bottom:5px;
}

.MapControlsButton:hover {
  background:$darkTurquoise;
}

.TenementHolderSearchContainer {
  margin-top:10px;
  display:flex;
  flex-direction:column;
  width:500px;
  height:500px;
}

.TenementHolderSearchResultContainer {
  font-size:15px;
  margin-top:20px;
  height:500px;
  overflow-y:auto;
}

.TenementHolderSearchResult {
  text-decoration: underline;
  padding:5px;
  transition: all 0.1s;
}

.TenementHolderSearchResult.selected {
  color:$brightTurquoise;
  font-weight: bold;
}

.TenementHolderSearchResult:nth-child(odd) {
  background: #444;
}

.TenementHolderSearchResult:hover {
  background:$darkTurquoise;
}

.TenementsHolderCancelBtn {
  font-size:15px;
  background:#222222;
  padding:5px;
  margin-left:5px;
  transition: all 0.2s;
}

.TenementsHolderCancelBtn:hover {
  background: $darkTurquoise;
}

.TenementHolderSearchButton {
  padding: 5px;
  font-size: 15px;
  background: #222;
  display: inline-block;
  margin-top: 5px;
  transition: all 0.2s;
}

.TenementHolderSearchButton:hover {
  background: $darkTurquoise;
}
