.SlideContainer {
  display:flex;
  position:absolute;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  pointer-events: none;
  overflow: hidden;
  justify-content: center;
  flex-flow: column wrap;
  align-content: center;
}

.Slide {
  display:block;
  font-family: Sans-Serif;
  pointer-events: auto;
  background-color: #333333;
  overflow: hidden;
  align-self: center;
}

.SlideElementText {
  font-family: sans-serif;
  font-size: 28px;
  color:#FFFFFF;
  user-select:none;
  cursor:default;
}

.SlideElementVideo {
  font-family: Sans-Serif;
  font-size: 28px;
  color:#FFFFFF;
  user-select:none;
  cursor:default;
  pointer-events: auto;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: $bgBlackTransparent
}

.SlideElementVideo iframe {
  position:absolute;
  left:0px;
  top:0px;
}

.LoadingYoutube {
  display:block;
  text-align: center;
}


.SlideElementImage {
  user-select:none;
  cursor:default;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
  background-position: center;
}

.SlideCloseButton {
  position:absolute;
  right:0px;
  width:20px;
  height:20px;
  display:block;
  user-select:none;
  cursor:default;
  display: flex;
  justify-content: center;
  background-color: #000000;
  pointer-events: auto;
}

.SlideCloseButton i {
  color:#FFFFFF;
  line-height:20px;
  font-size: 20px;
}



//NEW SLIDES MOVE TO SEP FILE

.NewSlideControls {
  position: absolute;
  right: 0px;
  top: 50px;
  background: #333;
  color: #FFF;
  padding: 5px;
  font-family: sans-serif;
  font-size: 20px;
}
