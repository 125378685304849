.passwordForm {
  display: flex;
  padding: 10px;
  background-color: #333333;
  color: #FFFFFF;
  font-family: Sans-Serif;
  position: absolute;
  align-items:center;
  flex-direction:column;
  pointer-events: auto;
}

.passwordFormTitle {
  font-size: 25px;
  margin-bottom: 5px;
  user-select: none;
}

.tokenExpiredWarning {
  text-align: center;
  background-color: #FF0000;
  padding: 5px;
  font-size: 20px;
}

.passwordForm input {
  font-size: 18px;
  border: 0px;
  margin-bottom: 5px;
  user-select: none;
  padding:5px;
}

.passwordFormSubmit {
  font-size: 25px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #555555;
  transition: background-color 0.25s;
  user-select: none;
  margin-top: 5px;
}

.passwordFormSubmit:hover {
  background-color: $darkTurquoise;
}

.errorsBoxPassword {
  text-align: left;
  background-color: #bd0000;
  padding: 5px;
}

.SignInForm {
  display: flex;
  padding: 10px;
  background-color: #333333;
  color: #FFFFFF;
  font-family: Sans-Serif;
  position: absolute;
  align-items:center;
  flex-direction:column;
  pointer-events: auto;
	z-index: 99;
}

.SignInFormTitle {
  font-size: 30px;
  margin-bottom: 5px;
  user-select: none;
  width: 100%;
}

.SignInForm input {
  font-size: 15px;
  border: 0px;
  margin-bottom: 5px;
  user-select: none;
  padding:5px;
  width: 300px;
  outline: none;
}

.SignInFormSubmit {
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #555555;
  transition: background-color 0.25s;
  user-select: none;
  width: auto!important;
  color: #FFFFFF!important;
}

.SignInFormSubmit:hover {
  background-color: $darkTurquoise;
}

.errorsBoxSignIn {
  text-align: left;
  background-color: #bd0000;
  padding: 5px;
}

.NewAccountText {
}

.NewAccountTextRegister {
  color: #25b9a4;
  text-decoration: underline;
  transition: all 0.2s;
}

.NewAccountTextRegister:hover {
  color: #04feda;
}

.SignInBottomControls {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.SignInClose {
  position: absolute;
  right: 2px;
  top: 2px;
}
