.CompanyForm {
  background: #222222;
  color: #FFFFFF;
  font-family: Sans-Serif;
  width: 200px;
  height: 200px;
  padding-left: 20px;
  padding-top: 20px;
  position: relative;
  pointer-events: auto;
}
