.toggleEditButton {
  font-size: 14px;
  line-height: 30px;
  padding-left: 5px;
  font-style: italic;
}

.toggleEditButton.project {
  line-height: 20px;
  opacity: 1.0;
  transition: all 0.25s;
}

.toggleEditButton:hover {
  text-decoration: underline;
}

.CompanyEntryHeader {
  display:flex;
}

.CompanyEntryContainer {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  background-color: #333333;
}

.CompanyEntryContainer:nth-child(odd) {
  background-color: #252525;
}

.ProjectDirectoryContainer {
  display:flex;
  flex-direction:column;
  pointer-events:auto;
  padding:10px;
  background-color:#333333;
  font-size:20px;
  color:#FFFFFF;
  font-family:$defaultFont;
  height:500px;
  width:500px;
  overflow-y:hidden;
  z-index: 5;
}

.ScrollableBox {
  width:500px;
  overflow-y:auto;
}

.CreateCompanyButton {
  height: 36px;
  font-family: $defaultFont;
  color: #FFFFFF;
  text-align: center;
  line-height: 36px;
  background-color: $darkTurquoise;
  transition: all 0.5s;
}

.CreateCompanyButton:hover {
  background-color: $brightTurquoise;
}


.CreateProjectButton {
  font-family: $defaultFont;
  background-color: $darkTurquoise;
  color: #FFFFFF;
  transition: all 0.5s;
  display: inline-block;
  padding: 2px;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.CreateProjectButton:hover {
  background-color: $brightTurquoise;
}


.ProjectSearchInput {
  font-size: 22px;
  border: 0px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  outline: none;
}

.NoResultsFound {
  text-align: center;
}

.CompanyHeaderLink {
  color:#FFFFFF;
  text-decoration:none;
  user-select: none;
}

.CompanyHeaderLink:hover {
  text-decoration: underline;
}

.ProjectEntryHeader {
  padding-left:10px;
  font-size:15px;
  display: flex;
}

.ProjectEntryHeader:hover .toggleEditButton.project {
  opacity: 1.0;
}

.ProjectEntryLink {
  color: #FFFFFF;
  text-decoration: none;
  user-select: none;
}

.ProjectEntryLink:hover {
  text-decoration: underline;
}

.CompanyField {
  display: flex;
}

.AddingUserField {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 0px;
  transition: height 0.5s;
  align-items: flex-start;
}

.AddingUserField.visible {
  height: 50px;
}

.lastSeenCounter {
  margin-left: 5px;
  border-radius: 5px;
  background-color: #555555;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  line-height: 20px;
}


.AddingUserField input {
  width: 250px;
  border: none;
  padding-left: 3px;
  font-size: 15px;
  line-height: 15px;
}

.AddingUserSave {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #555555;
  transition: background-color 0.25s;
  text-align: center;
  display: inline-block;
  width:110px;
}

.AddingUserSave:hover {
  background-color: #666666;
}


.CompanyFieldSave {
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #555555;
  transition: background-color 0.25s;
}

.CompanyFieldSave:hover {
  background-color: #666666;
}

.CompanyFieldLabel {
  position: absolute;
  top: -14px;
  font-size: 14px;
}

.ProjectsUsersList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top:1px solid #FFFFFF;
}

.ProjectsList {
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #222222;
  padding: 5px;
  padding-bottom: 10px;
  width: calc(100% - 10px);
  border-radius: 5px;
}

.UsersList {
}

.EditProjectEntry {
  font-size: 12px;
  background-color: #217c21;
  border-radius: 5px;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 20px;
  }

.ProjectsEntryContainer {
  display: flex;
  margin-top: 5px;
}

.EditUsersEntry {
  font-size: 15px;
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
}

.UserNameField {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
  align-items: center;
}

.RemoveUserButton {
  font-size: 12px;
  line-height: 20px;
  padding-right: 5px;
  padding-left: 5px;
  transition: background-color 0.25s;
  background: #990c0c;
  border-radius: 5px;
  margin-left: 2px;
  margin-right: 2px;
}

.RemoveUserButton.resetPassword {
  background: #217c21;
}

.UploadProgressContainer {
  height: 20px;
  background: #a6a6a6;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.UploadProgressBar {
  background-image: linear-gradient(45deg, #25b9a4 25%, #4fe8d4 25%, #4fe8d4 50%, #25b9a4 50%, #25b9a4 75%, #4fe8d4 75%, #4fe8d4 100%);
  height: 20px;
}


.RemoveUserButton:hover {
  background-color: #555555;
}


.BackButtonDirectory {
  float: left;
  display: inline-block;
  position: absolute;
  background-color: #444444;
  padding: 5px;
  font-size: 14px;
  transition: background-color 0.25s;
}

.BackButtonDirectory:hover {
  float: left;
  display: inline-block;
  position: absolute;
  background-color: #666666;
  padding: 5px;
  font-size: 14px;
}



.CreateCompanyForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CreateProjectForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.CreateCompanyInput {
  margin-top: 10px;
}

.CreateProjectInput {
  margin-top: 10px;
}


.CreateCompanySubmit {
  background-color: $darkTurquoise;
  width: 172px;
  text-align: center;
  margin-top: 10px;
  height: 30px;
  font-size: 25px;
  line-height: 30px;
  transition: all 0.25s;
}

.CreateCompanySubmit:hover {
  background-color: $brightTurquoise;
}

.CreateProjectSubmit {
  background-color: $darkTurquoise;
  width: 172px;
  text-align: center;
  margin-top: 10px;
  height: 30px;
  font-size: 25px;
  line-height: 30px;
  transition: all 0.25s;
}

.CreateProjectSubmit:hover {
  background-color: $brightTurquoise;
}

.LatLongInput {
  display: flex
}

.DisabledWarning {
  text-align: center;
  color: #ff2424;
  font-weight: bold;
}

.EditorTokensList {
  max-height: 150px;
  overflow-y: scroll;
}

.filterButton {
  margin-right: 5px;
  border-radius: 5px;
  background: #FF0000;
  padding: 0px 5px 0px 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.filterControlsContainer {
  display: flex;
  padding: 5px 0px 5px 0px;
}

.filterButton.on {
  background:#1c7d1c;
}
