.AnimationTimelineBar {
  position: absolute;
  background-color: rgba(20, 20, 20, 0.8);
  width: 100%;
  bottom: 0px;
  transition: all 0.5s;
  font-family: $defaultFont;
  line-height: 50px;
  font-size: 20px;
  color: #ffffff;
  pointer-events: none;
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ControlsPanel {
  display: flex;
  align-items: center;
  height: 50px;
  align-items: center;
}

.ControlsPanel .button {
  display: flex;
  align-items: center;
  color: #ffffff;
  user-select: none;
  cursor: default;
  pointer-events: auto;
  margin-left: 5px;
  margin-right: 5px;
  background: #222222;
  padding-left: 5px;
  padding-right: 5px;
  transition: background 0.5s;
  border: 1px solid #ffffff;
}

.ControlsPanel .button:hover {
  background: $brightTurquoise;
}

.button i {
  line-height: 40px;
  //font-size: 40px;
}

.ControlsPanel .button.disabled {
  opacity: 0.2;
}

.ControlsPanel .button.disabled:hover {
  background: #222222;
}

.ControlsPanel .button.active {
  background: $darkTurquoise;
}

.ControlsPanel .button.active:hover {
  background: $brightTurquoise;
}

.AnimationTimelineBar.hidden {
  transform: translateY(125px);
}

.AnimationTimelineBar.TimelineView {
  transform: translateY(0px);
}

.AnimationTimelineBar.hidden.TimelineView {
  transform: translateY(0px);
}

.SelectBar {
  display: flex;
  user-select: none;
  pointer-events: auto;
  cursor: default;
  height: 30px;
  width: 310px;
  border: 1px solid #ffffff;
  background: #5b5b5baa;
  transition: background 0.2s;
}

.SelectBar:hover {
  background: $brightTurquoise;
}

.StatusBar {
  display: flex;
  user-select: none;
  font-size: 15px;
  line-height: 30px;
  margin-left: auto;
  padding-right: 10px;
}

.StatusBar .button {
  pointer-events: auto;
  cursor: default;
  user-select: none;
  display: flex;
  align-items: center;
}

.AnimationList {
  display: block;
  width: auto;
  position: absolute;
  bottom: 30px;
  background-color: $gallery;
  opacity: 1;
  transition: all 0.5s;
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.6);
  width: 250px;
  max-height: 250px;
  overflow-y: auto;
  left: -25px;
}

.AnimationEntry {
  padding-left: 10px;
  padding-right: 10px;
  color: #000000;
  font-size: 15px;
  line-height: 40px;
}

.AnimationEntry.CreateAnimation {
  background-color: $brightTurquoise;
  font-weight: bold;
  text-align: center;
  font-style: italic;
}

.AnimationEntry:hover {
  background-color: $silver;
}

.AnimationEntry.CreateAnimation:hover {
  background-color: $darkTurquoise;
}

.AnimationTimelineBar.hidden .AnimationList {
  opacity: 0;
}

.AnimationTimelineBar.TimelineView.hidden .AnimationList {
  opacity: 1;
}

.AnimationBar {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 50px;
  flex-wrap: wrap;
}

.TimelineBar {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.timeline {
  position: absolute;
  width: 90%;
  max-width: 600px;
  display: block;
  pointer-events: all;
}

.timelineTitle {
  position: absolute;
  top: -22px;
  font-size: 15px;
  line-height: 20px;
}

.timelineClose {
  position: absolute;
  top: -22px;
  font-size: 15px;
  right: 0px;
  padding-right: 5px;
  line-height: 20px;
  background: #333333;
  padding-left: 5px;
  border: 1px solid #fff;
  transition: background 0.2s;
}

.timelineClose:hover {
  background: $brightTurquoise;
}

.sliderBackground {
  display: block;
  position: absolute;
  background-color: #000000;
  width: 100%;
  height: 10px;
  top: 2px;
  padding-right: 4px;
  border-radius: 5px;
}

.sliderProgressFill {
  display: block;
  position: absolute;
  background-color: $activePip;
  width: 100%;
  height: 10px;
  top: 2px;
  padding-right: 4px;
  border-radius: 5px;
  transition: all 0.2s;
}

.pipContainer {
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
  height: 8px;
  margin-top: 3px;
  position: absolute;
  margin-left: 10px;
  pointer-events: none;
}

.pip {
  display: block;
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  border-radius: 50%;
  left: 0%;
  position: absolute;
  z-index: 0;
}

.timelineSlider {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 10px;
  -webkit-appearance: none;
  background: none;
  outline: none;
  border-radius: 4px;
  transition: all 0.2s;
  pointer-events: all;
}

.timelineSlider.disabled::-webkit-slider-thumb {
  background: #333333;
}

.timelineSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: $timelineThumb;
  cursor: pointer;
  border-radius: 50%;
  border: #ffff 2px solid;
  transition: all 0.2s;
}

.timelineTick {
  width: 3px;
  height: 10px;
  display: block;
  background-color: #000000;
  margin-left: 1px;
  margin-top: 5px;
}

.pip.active .timelineTickText {
  color: $activePip;
}

.timelineTickText {
  display: block;
  width: 95px;
  height: 35px;
  font-family: "Arial";
  margin-left: -45px;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  margin-top: 3px;
  pointer-events: all;
  user-select: none;
}

.AnimationSelector {
  font-size: 15px;
  line-height: 30px;
  position: absolute;
  margin-left: 25px;
}

.SelectorArrow {
  display: flex;
  padding-top: 4px;
}
