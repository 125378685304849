@import "ModelCollection.scss";

.SideBar {
  display: block;
  position: absolute;
  top: 0px;
  height: 100%;
  width: 350px;
  background-color: #232323;
  box-shadow: none;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: transform 0.5s;
  transform: translate(-350px, 0px);
  z-index: 10;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.SideBar.visible {
  left: 0px;
  //box-shadow: 2px 0px 2px rgba(0,0,0,0.8);
  transform: translate(0px, 0px);
}

.SideBarTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  cursor: default;
  user-select: none;
}

.SideBarTitle img {
  width: 250px;
  user-select: none;
  cursor: pointer;
}

.TabsContainer {
  background-color: $panelLight;
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.TabButton {
  display: flex;
  color: $buttonInactive;
  cursor: default;
  user-select: none;
  padding: 2px 0px 2px 0px;
}

.TabButton.active {
  color: $tabSelected;
  border-bottom: 2px solid $tabSelected;
}

.TabButton i {
  font-size: 25px;
  color: $buttonInactive;
  transition: all 0.2s;
}

.TabButton:hover i {
  color: $brightTurquoise;
}

.SideBarTabs {
  z-index: 5;
  width: 100%;
  background-color: #444444;
  cursor: default;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DropDownContainer {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.8);
  background: #eaeaea;
  width: 100%;
  position: absolute;
  top: 30px;
}

.DropDownEntry {
  display: flex;
  height: 30px;
  font-family: $defaultFont;
  padding-left: 10px;
  line-height: 30px;
  justify-content: space-between;
}

.DropDownEntry.empty {
  font-style: italic;
}

.DropDownEntry.header {
  padding-left: 0px;
  text-align: center;
  font-size: 15px;
  background-color: $panelDark;
  color: $tabSelected;
  display: flex;
  justify-content: center;
}

.DropDownEntry.header:hover {
  background-color: $panelDark;
}

.DropDownEntry.link {
  padding-right: 10px;
  background-color: $dropDownBackground;
  padding-left: 10px;
  border-left: 1px solid #000000;
}

.DropDownEntry.link i {
  line-height: 30px;
}

.DropDownEntry:hover {
  background-color: $silver;
}

.SideBarPanelHeader {
  width: 100%;
  height: 35px;
  border-top: 1px solid $panelBorder;
  font-size: 17px;
  font-family: $defaultFont;
  color: $gallery;
  line-height: 35px;
  display: flex;
  user-select: none;
  cursor: default;
  position: relative;
}

.SideBarPanelHeader.contextOpen {
  color: $brightTurquoise;
  font-weight: bold;
}

.SideBarPanelContextMenu {
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  color: #3a3a3a;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.8);
  font-size: 12px;
  z-index: 99;
  line-height: normal;
}

.SideBarPanelContextMenu.disabled {
  text-decoration: line-through;
}

.SideBarContextMenuEntry {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #333333;
  padding: 5px;
}

.SideBarContextMenuEntry.MenuEntryWarning {
  background-color: #fd7272;
}

.SideBarContextMenuEntry:hover {
  background-color: $brightTurquoise;
}

.SideBarContextMenuEntry.MenuEntryWarning:hover {
  background-color: #de1a1a;
}

.SideBarPanel.open i {
  color: $buttonActive;
}

.SideBarPanel.open .SideBarPanelBody {
  height: auto;
}

.SideBarPanelBody {
  height: 0px;
  width: 100%;
  background-color: $panelLight;
  position: relative;
}

.SideBarPanelBody.visible {
  height: auto;
}

.InventumTitle {
  display: block;
  width: 200px;
  font-family: $defaultFont;
  font-size: 25px;
  color: $inventumTitle;
  padding-left: 10px;
  padding-top: 5px;
}

.InventumLogo {
  display: block;
  width: 270px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 10px;
  cursor: pointer;
}

.InventumDescription {
  display: block;
  width: 100%;
  font-family: $defaultFont;
  font-size: 18px;
  color: $inventumDescription;
  padding-left: 10px;
  padding-top: 5px;
}

.SideBarCloseButton {
  position: absolute;
  top: 2px;
  right: 5px;
  cursor: default;
  user-select: none;
}

.SideBarCloseButton:hover i {
  color: #ffffff;
}

.SideBarCloseButton i {
  color: $buttonInactive;
}

.PanelExpandButton {
  width: 20px;
  height: 35px;
  display: block;
  padding-right: 5px;
  cursor: pointer;
}

.PanelExpandButton i {
  font-size: 25px;
  line-height: 35px;
  text-align: center;
}

.PanelTitle {
  width: 250px;
  overflow: hidden;
  font-size: 15px;
  cursor: pointer;
}

.PanelTitle.Editing {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  font-size: 12px;
}

.PanelTitleEditButton {
  padding-left: 5px;
  padding-right: 5px;
  background-color: #555555;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  height: 25px;
  overflow: hidden;
  line-height: 25px;
  transition: all 0.5s;
}

.PanelTitleEditButton:hover {
  background-color: $brightTurquoise;
}

.HeaderControls {
  cursor: pointer;
}

.HeaderControls i {
  line-height: 35px;
  font-size: 20px;
  color: $buttonInactive;
  padding-left: 5px;
}

.ContentContainer {
  position: absolute;
  top: 75px;
  left: 0px;
  width: 100%;
  //height: calc(100% - 75px);
  height: calc(100% - 20px);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.PanelContainer {
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: auto;
  transition: top 0.5s;
}

.PanelContainer.noGap {
  top: 0px;
}

.PlaybackContainer {
  height: 0px;
  width: 100%;
  transition: height 0.5s;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background-color: #333333;
}

.PlaybackContainer i {
  color: #ffffff;
  font-size: 35px;
  line-height: 50px;
}

.SideBarTabs.Controls .PlaybackContainer {
  height: 50px;
}

.SideBarTabs.Controls ~ .PanelContainer {
  height: calc(100% - 110px);
  top: 110px;
}

.PlaybackButton {
  width: 40px;
  height: 40px;
  display: block;
  color: $buttonInactive;
  padding-left: 20px;
  padding-right: 20px;
  cursor: default;
  user-select: none;
}

.CreateNewCollection {
  height: 36px;
  font-family: $defaultFont;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
  background-color: $darkTurquoise;
  transition: all 0.5s;
  user-select: none;
}

.CreateNewCollection:hover {
  background-color: $brightTurquoise;
}

.MenuButtonPulser {
  position: absolute;
  top: 15px;
  width: 30px;
  height: 30px;
  z-index: -1;
}

.pulsating-circle {
  z-index: -1;
  position: absolute;
  left: 50%;
  top: 15px;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #01a4e9;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
