.ModelCollection {
  width: 100%;
  display: block;
  background-color: #424242;
  user-select: none;
  cursor: default;
}

.ModelEntry {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: $defaultFont;
  color: #fff;
  cursor: default;
  user-select: none;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.ModelEntry.active {
  z-index: 999;
}

.ModelEntry.active .ModelTitle {
  color: $brightTurquoise;
}

/*.ModelEntry:nth-child(odd) {
  background-color: $panelEntryAlt;
}*/

.ModelTitle {
  display: block;
  width: 160px;
  text-align: left;
  overflow: hidden;
  font-size: 12px;
}

.ModelTitle.editing {
  display: flex;
  align-items: center;
  width: 250px;
  justify-content: space-evenly;
}

.ModelTitle.highlighted {
  color: $tabSelected;
}

.ModelColorPicker {
  display: flex;
  width: 35px;
  justify-content: center;
}

.ColorPreview {
  width: 15px;
  height: 15px;
  border: 1px solid #ffffff;
  display: block;
  background-color: #ff00b0;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.8);
  transition: all 0.5s;
  border-radius: 2px;
}

.ColorPreview:hover {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  border: 1px solid $brightTurquoise;
}

.ModelWireframeToggle {
  display: block;
}

.ModelZoomToggle {
  display: block;
  width: 35px;
}

.ModelLabelToggle {
  display: block;
  height: 35px;
  width: 35px;
}

.ModelVisibilityToggle {
  display: block;
}

.model-controls {
  line-height: 30px;
  padding-left: 5px;
}

.ModelEntry i {
  font-size: 20px;
  line-height: 30px;
}
.ModelEntryHeader {
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  color: #ffffff;
  font-family: Sans-Serif;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 5px;
}

.ModelEntryHeader div {
  padding-left: 9px;
}

.MaterialEditorContainer {
  height: 0px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0px;
  background: #383838;
}

.MaterialEditorHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  line-height: normal;
  color: #fff;
  font-size: 14px;
  padding-left: 5px;
}

.MaterialEditorCloseBtn {
  padding-right: 10px;
}

.MaterialEditorContainer.open {
  height: auto;
}

.ColorModeSelector {
  display: flex;
  justify-content: space-evenly;
  padding-top: 5px;
}

.ColorModeButton {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  padding-left: 10px;
  padding-right: 10px;
  background: #333333;
  color: #ffffff;
}

.ColorModeButton.active {
  background-color: #25b9a4;
  color: #000000;
}

.MaterialDrawer {
  width: 100%;
}

.MaterialDrawerBody {
  padding: 10px;
}

.MaterialDrawerHeader {
  color: #fff;
}

.MaterialDrawerHeader i {
  line-height: unset;
}

.ColorEditorContainer {
  display: flex;
  justify-content: center;
}

.TextureChangeBox {
  color: #999;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s;
  border: 1px solid #fff;
}

.TextureChangeBox:hover {
  color: #fff;
}

.TexturePreviewBox {
  width: 256px;
  height: 256px;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.TexturePreviewBox:hover .TextureChangeBox {
  opacity: 1;
}

.TextureAddBox {
  color: #ffffff;
  padding: 5px;
  text-align: center;
  border: 2px dashed #ffffff;
  transition: background-color 0.25s;
}

.TextureAddBox:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.LoadModelButton {
  text-decoration: underline;
  transition: color 0.25s;
}

.LoadModelButton:hover {
  color: #fff;
}
