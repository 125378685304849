.map-horizontal-controls {
  display: flex;
  position: absolute;
  height: 25px;
  top: 5px;
  right: 0px;
  z-index: 5;
  justify-content: flex-end;
  transition: opacity 0.4s;
}

.map-horizontal-button {
  display: inline-block;
  background: #232323;
  z-index: 5;
  height: 25px;
  color: #ffffff;
  vertical-align: middle;
  line-height: 25px;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: all 0.2s;
  font-family: $defaultFont;
  user-select: none;
  border-radius: 10px;
}

.map-horizontal-button:hover {
  background: $buttonHover;
}

.edit-dropdown-entry {
  display: inline-block;
  background: #232323;
  z-index: 5;
  height: 25px;
  color: #ffffff;
  vertical-align: middle;
  line-height: 25px;
  font-size: 15px;
  padding-left: 5px;
  cursor: default;
  transition: all 0.2s;
  font-family: $defaultFont;
  user-select: none;
  width: 100%;
  border-bottom: 1px solid #fff;
}

.edit-dropdown-entry i {
  font-size: 15px;
  padding-right: 10px;
  line-height: 25px;
}

.edit-dropdown-entry:hover {
  background: $buttonHover;
}

.map-horizontal-button.open {
  color: #00fff3;
}

.map-horizontal-button.disabled {
  opacity: 0.2;
}

.map-horizontal-button .material-icons {
  font-size: 15px;
  padding-right: 5px;
  position: relative;
  top: 2px;
}

.clear-filter-button {
  position: absolute;
  top: 30px;
  background: #444444;
  padding: 5px;
  font-family: $defaultFont;
  color: #fff;
  transition: background 0.2s;
}

.clear-filter-button:hover {
  background: $buttonHover;
}

.PlaceholderComingSoon {
  display: block;
  font-family: $defaultFont;
  font-size: 25px;
  height: 40px;
  text-align: center;
  line-height: 35px;
}

.map-horizontal-dialogbox {
  position: absolute;
  display: none;
  width: 400px;
  height: 100px;
  left: -350px;
  user-select: none;
  cursor: default;
}

.map-horizontal-dialogbox.right {
  left: 0px;
}

.map-horizontal-dialogbox .dialogContent {
  position: absolute;
  top: 15px;
  font-family: $defaultFont;
  color: #ffffff;
  background-color: $panelLight;
  font-size: 20px;
  padding-top: 5px;
  width: 100%;
}

.map-horizontal-dialogbox .dialogArrow {
  width: 0;
  height: 0;
  position: absolute;
  right: 0px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid $panelLight;
}

.map-horizontal-dialogbox.right .dialogArrow {
  right: auto;
}

@media only screen and (max-width: 600px) {
  body .backHomeButton.logo {
    top: 110px;
  }

  body .backHomeButton {
    top: 80px;
    transform: translate(5px, 0px);
  }
}

.backHomeButton {
  color: #ffffff;
  font-family: Sans-Serif;
  background: #333333;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 20px;
  position: absolute;
  top: 40px;
  transform: translate(55px, 0px);
  transition: background 0.2s;
}

.backHomeButton.logo {
  top: 80px;
}

.backHomeButton.moved {
  transform: translate(340px, 0px);
}

.backHomeButton:hover {
  background: $brightTurquoise;
}

.editDropdownContainer {
  background: #444444;
  position: absolute;
  width: 180px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  border-top: 1px solid #ffffff;
  overflow: hidden;
  left: 0px;
  border-left: 1px solid #fff;
}
