.LabelsPanel {
  background: #424242;
  font-family: Sans-Serif;
  pointer-events: all;
  color: #fff;
  padding: 5px 0px 0px 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.EditorPanelHeader {
  display: flex;
  align-items: center;
}

.EditorPanelHelpButton {
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditorPanelHelpButton i {
  font-size: 14px;
  color: #aaa;
  transition: color 0.2s;
}

.EditorPanelHelpButton i:hover {
  color: #fff;
}

.LabelTypeDrawer {
  margin-bottom: 5px;
}

.CreateNewLabelBtn {
  border: 1px solid #0b0b0b;
  border-radius: 2px;
  padding: 1px;
  margin-right: 5px;
  background: #4b4b4b;
  display: flex;
  transition: background-color 0.2s;
}

.CreateNewLabelBtn:hover {
  background-color: #008cf1;
}

.CreateNewLabelBtn i {
  font-size: 15px;
}

.PanelOpenStatus {
  margin-right: 5px;
}

.LabelHeader {
  display: flex;
  align-items: center;
  padding: 5px;
  position: relative;
  transition: background-color 0.4s;
}

.LabelHeader:hover {
  background-color: #666;
}

.LabelHeader i {
  font-size: 12px;
}

.LabelHeaderBtn {
  border: 1px solid #0b0b0b;
  border-radius: 2px;
  padding: 3px;
  margin-right: 5px;
  background-color: #5b5b5b;
  display: flex;
  transition: background-color 0.2s;
}

.LabelHeaderBtn.delete {
}

.LabelHeaderBtn:hover {
  background-color: #008cf1;
}

.LabelHeaderBtn.delete:hover {
  background-color: #a00000;
}

.LabelHeaderBtn.active {
  background-color: #4a9e00;
}

.LabelTypeDrawerHeader {
  display: flex;
  align-items: center;
  transition: background-color 0.4s;
  padding: 5px;
}

.LabelTypeDrawerHeader:hover {
  background-color: #555;
}

.EditorTabIcon {
  background-color: #2b2b2b;
  margin-left: 5px;
  margin-bottom: 5px;
  border-radius: 5px 0px 0px 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
  padding: 6px;
  display: flex;
  transition: background-color 0.4s;
  cursor: default;
}

.EditorTabIcon:hover {
  background-color: #008cf1;
}

.EditorTabIcon.active {
  background: #424242;
}

.EditorTabIcon i {
  font-size: 16px;
  color: #fff;
}

.EditorPanel {
  background: #424242;
  font-family: Sans-Serif;
  pointer-events: all;
  color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  min-width: 500px;
  min-height: 500px;
}

.LabelTextPill {
  display: flex;
  align-items: center;
  background-color: #5b5b5b;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  transition: background-color 0.4s;
  margin-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  &:not(.active):hover {
    background-color: #008cf1;
  }
}

.VideoSettingPill {
  display: flex;
  align-items: center;
  background-color: #5b5b5b;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  transition: background-color 0.4s;
  margin-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  &:not(.active):hover {
    background-color: #008cf1;
  }
}

.VideoSettingPill.active {
  background-color: #4a9e00;
}

.TextPillDeleteButton {
  display: flex;
  opacity: 0.4;
  transition: opacity 0.2s;
}

.TextPillDeleteButton:hover {
  i {
    color: #a00;
  }
  opacity: 1;
}

.LabelTextPillContainer {
  display: flex;
  max-width: 70%;
}

.ExistingLabelTextPills {
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 90%;
}

.PanelValueCell {
  border-radius: 5px;
  background-color: #585858;
  min-width: 100px;
  transition: background-color 0.4s;
  padding: 5px;
  font-size: 12px;
}

.PanelValueCell > div > input {
  width: 100px;
  border-radius: 5px;
  outline: none;
  border: 0px;
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
}

.PanelValueCellUpdateBtn {
  transition: background-color 0.4s;
  padding: 3px;
  border-radius: 5px;
  background: #464646;
}

.PanelValueCellUpdateBtn:hover {
  background: #008cf1;
}

.PanelValueCell:hover {
  background-color: #008cf1;
}

.PanelKeyCell {
  margin-right: 5px;
  font-size: 12px;
  text-align: right;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.StyleClearButton {
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  transition: background-color 0.4s;
  background-color: #600;
  padding: 0px 5px 0px 5px;
  font-size: 12px;
}

.StyleClearButton:hover {
  background-color: #900;
}

.LabelDrawCollection {
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.EditorDrawerItemHeader {
  padding: 5px;
  transition: background-color 0.4s;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.EditorDrawerItemHeader > span > i {
  font-size: 12px;
}

.EditorDrawerItemHeader:hover {
  background-color: #666;
}

.EditorDrawerItemHeaderButton {
  margin-left: 5px;
  background: #5b5b5b;
  display: flex;
  align-items: center;
  padding: 3px;
  border: 1px solid #0b0b0b;
  justify-content: center;
  transition: background-color 0.4s;
}

.EditorDrawerItemHeaderButton:hover {
  background: #008cf1;
}

.EditorDrawerItemHeader i {
  font-size: 12px;
}

.EditorLabelTextList {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.EditorTextButtonsContainer {
  display: flex;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.EditorTextButton {
  background: #5b5b5b;
  margin-right: 5px;
  padding: 3px;
  border: 1px solid #000;
  transition: background-color 0.4s;
}

.EditorTextButton:hover {
  background: #008cf1;
}

.ColorCloseButton {
  background: #5b5b5b;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #111;
  transition: background-color 0.4s;
}

.ColorCloseButton:hover {
  background-color: #008cf1;
}

.ColorValueCell {
  border-radius: 5px;
  background-color: #585858;
  min-width: 100px;
  transition: background-color 0.4s;
  padding: 10px 5px 10px 5px;
  font-size: 12px;
  border: 2px solid rgba(0, 0, 0, 0);
  transition: border 0.4s;
  box-sizing: border-box;
}

.ColorValueCell:hover {
  border: 2px solid #008cf1;
}

.LabelTextPillClose {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #222;
  opacity: 0;
  transition: opacity 0.4s;
}

.LabelTextPill:hover > .LabelTextPillClose {
  opacity: 0.4;
}

.LabelTextPill:hover > .LabelTextPillClose:hover {
  opacity: 1;
}

.StaticImageEditor {
  width: 100%;
  height: 300px;
  position: relative;
}

.StaticImageChangeText {
  display: flex;
  flex-direction: column;
  top: 0px;
  font-size: 1.2rem;
  opacity: 1;
}

.StaticImagePreview {
  width: 100%;
  height: 200px;
  margin-top: 5px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.CenterLabelPositionButton {
  padding: 3px;
  background-color: #585858;
  border-radius: 5px;
  transition: background-color 0.4s;
  font-size: 0.8rem;
}

.CenterLabelPositionButton:hover {
  background-color: #008cf1;
}

.CreateLightsButton {
  border-radius: 5px;
  background-color: #5b5b5b;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  transition: background-color 0.4s;
  border: 1px solid #0b0b0b;
}

.CreateLightsButton i {
  font-size: 12px;
}

.CreateLightsButton:hover {
  background-color: #008cf1;
}

.ModelGroupNamePill {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: #777;
  padding-left: 5px;
}

.ModelNamePill {
  margin-left: 5px;
  font-size: 14px;
  border-radius: 5px;
  background-color: #5b5b5b;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
  transition: background-color 0.4s;
}

.ModelNamePill:hover {
  background-color: #008cf1;
}

.ModelNamePill.active {
  background-color: #4a9e00;
}

.ModelsCopyPasteBtn {
  margin-left: 5px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: rgb(88, 88, 88);
  font-size: 12px;
  transition: background-color 0.4s;
}

.ModelsCopyPasteBtn:hover {
  background-color: #008cf1;
}

.WorldEditorBtn {
  border-radius: 5px;
  background-color: #5b5b5b;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  transition: background-color 0.4s;
  border: 1px solid #0b0b0b;
}

.WorldEditorBtn:hover {
  background-color: #008cf1;
}

.ModelPropsPanelHeader {
  display: flex;
  align-items: center;
  transition: background-color 0.4s;
}

.ModelPropsPanelHeader:hover {
  background-color: #555;
}

.RemoveImageButton {
  position: absolute;
  right: 5px;
  font-size: 12px;
  font-weight: 100;
  top: 5px;
  background: #8f0000;
  padding: 4px;
  border-radius: 5px;
  text-shadow: none;
  transition: background-color 0.4s;
}

.RemoveImageButton:hover {
  background-color: #d00000;
}

.Vec3ResetButton {
  text-align: center;
  height: 14px;
  width: 45px;
  font-size: 12px;
  background-color: #8f0000;
  border-radius: 2px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  transition: background-color 0.4s;
}

.Vec3ResetButton:hover {
  background-color: #d00000;
}

.ModelEntryHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: background-color 0.4s;
}

.ModelEntryHeaderWrapper:hover {
  background-color: #555;
}

.FovResetButton {
  margin-left: 10px;
  padding: 4px;
  border: 1px solid #0b0b0b;
  border-radius: 2px;
  background-color: #4b4b4b;
  font-size: 12px;
  transition: background-color 0.2s;
}

.FovResetButton:hover {
  background-color: #008cf1;
}

.ApplyTextEditButton {
  margin-left: 5px;
  padding: 4px;
  background: rgb(45, 45, 45);
  text-align: center;
  border-radius: 2px;
  border: 1px solid #000;
  transition: background-color 0.2s;
}

.ApplyTextEditButton:hover {
  background-color: #008cf1;
}
