.LoginContainer {
  display: flex;
  background-color: #444444;
  overflow: hidden;
  color: #FFFFFF;
  font-family: Sans-Serif;
  height: 25px;
  //margin-left: 20px;
}

.LoginButton {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 25px;
  background-color: #444444;
  user-select: none;
  transition: all 0.2s;
}

.LoginButton:hover {
  background: $buttonHover;
}


.LoginButton.disabled {
  opacity:0.2;
}

.LogoutButton {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 25px;
  border-left: 1px solid #FFFFFF;
  transition: background-color 0.25s;
  user-select: none;
}

.LogoutButton:hover {
  background-color: $buttonHover;
}


.ErrorContainer {
  position: absolute;
  top: 25px;
  background-color: #444444;
  height: 25px;
  line-height: 25px;
  overflow: hidden;
  padding-left: 5px;
  border-top: 1px solid #FFFFFF;
  color: #FF0000;
  font-weight: bold;
  box-sizing: border-box;
  width: 100%;
}

.LoginContainer input {
  font-family: Sans-Serif;
  color: #FFFFFF;
  border: none;
  height: 25px;
  background-color: #444444;
  border-right: #ffffff solid 1px;
  padding-left: 5px;
}


.LoginContainer input:focus {
  outline-width: 0px;
}

.LoginContainer input::placeholder {
  color:#AAAAAA;
}

.ProfileControls {
  background: #444444;
  font-family: Sans-Serif;
  color: #FFFFFF;
  position: absolute;
  display: flex;
  right: 0px;
  margin-top: 10px;
  padding: 5px;
  flex-direction: column;
  min-width: 200px;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.8);
  pointer-events: auto;
}

.ProfileControls::after {
  content: " ";
  position: absolute;
  right: 0px;
  top: -10px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #444;
}

.SignOutBtn {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 5px;
  background: #333333;
  transition: all 0.2s;
}

.SignOutBtn:hover {
  background: $brightTurquoise;
}

.CompanyListEntry {
  padding-left: 5px;
  text-decoration: underline;
  margin-top: 2px;
}

.WelcomeMessage {
  margin-bottom: 5px;
}

.CompanyListEntry a {
  color: #FFFFFF;
}

.CompanyListEntry a:visited {
  color: #FFFFFF;
}
